import { Component, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BasicsRepository } from '../data/repository/basics.repository';
import { AssistantComponent } from '../ai/assistant/assistant.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SearchDialogComponent } from '../share/components/search-dialog/search-dialog.component';
import { NotificationService } from '../share/services/notification.service';
import * as Sentry from '@sentry/angular';
import { SheetRepository } from '../data/repository/sheet.repository';
import { UrlUtil } from '../share/utils/url.util';
import { UrlChangeListener, UrlEvents } from '../events/url.events';
import { FormFillDialogComponent } from '../formbuilder/form-fill-dialog/form-fill-dialog.component';
import { DialogService } from 'primeng/dynamicdialog';
import { ModalService } from '../modal/modal.service';
import { TourService } from '../onboarding/tour.service';
import { PreferencesDto } from '../data/objects/user.dtos';
import { SheetDto } from '../data/objects/Sheet.dto';
import { Translator } from '../share/utils/translator';
import { NavigationService } from '../navigation/navigation.service';
import { PreferencesEvents, PreferencesListener } from '../events/preferences.events';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
  providers: [DialogService],
})
export class PanelComponent implements UrlChangeListener, PreferencesListener {
  @ViewChild('assistant') assistant: AssistantComponent;

  initializing: boolean = true;
  showTut: boolean = false;

  preferences: PreferencesDto;

  isApp:boolean =false;

  constructor(
    private router: Router,
    private urlUtil: UrlUtil,
    private urlEvents: UrlEvents,
    private prefEvents: PreferencesEvents,
    private modalService: NgbModal,
    private baseRepo: BasicsRepository,
    private sheetRepo: SheetRepository,
    private tour: TourService,
    private dialog: ModalService,
    private translator: Translator,
    private navigator: NavigationService,
    private notificationService: NotificationService,
  ) {}

  onPreferencesChanged(prefs: PreferencesDto): void {
    this.preferences = prefs;
  }

  async ngOnInit() {
    await this.baseRepo.fetchDefaults();
    this.preferences = this.baseRepo.getPreferences();
    this.prefEvents.subscribePreferences(this);
    this.initializing = false;
    this.isApp = this.urlUtil.getParams()['scope']=='app';
    this.initMessaging();
    this.initSentryUser();
    this.checkParams();
    this.urlEvents.onUrlChange(this);
    // if (this.preferences.onboard !=undefined && !this.preferences.onboard) {
    if (this.preferences.onboard == false) {
      let isOwner = await this.baseRepo.amIOwner();
      if (isOwner) {
        this.initOnboarding();
      } else {
        // this.initEmployeeOnboarding();
      }
    } else {
      this.initTour();
    }
  }

  initTour() {
    if (!this.preferences.tour?.home) {
      setTimeout(() => {
        this.tour.startPanelTour();
      }, 3000);
    }
  }

  async initOnboarding() {
    if (!this.preferences.onboard) {
      this.dialog.openOnboardingDialog().onClose.subscribe((result) => {
        if (result == 'users') {
          this.navigator.goToSettingEmployees();
        } else {
          // window.location.reload();
          // this.initTour();
        }
      });
    }
  }

  private initEmployeeOnboarding() {
    this.dialog.openEmployeeOnboardingDialog().onClose.subscribe((result) => {
      this.initTour();
    });
  }

  initMessaging() {
    this.notificationService.requestPermission();
    this.notificationService.receiveMessage();
  }

  async initSentryUser() {
    let employee = await this.sheetRepo.getMyEmployee();
    Sentry.setUser({
      email: employee.Email,
      id: employee.UserId,
      ip_address: '{{auto}}',
      username: `${employee.FullName.firstName} ${employee.FullName.lastName}`,
    });
  }

  @HostListener('window:keydown.control.b', ['$event'])
  openAssistant(event: Event) {
    this.assistant.open();
    event.preventDefault();
  }

  @HostListener('window:keydown.control.k', ['$event'])
  @HostListener('window:keydown.meta.k', ['$event'])
  searchDialog(event: Event) {
    this.modalService.open(SearchDialogComponent, { size: 'lg' });
    event.preventDefault();
  }

  onUrlChange(url: string, params: any) {
    this.isApp = this.urlUtil.getParams()['scope']=='app';
    this.checkParams();
  }

  checkParams() {
    let params = this.urlUtil.getQueryParams();
    if (params['sheet'] && params['item']) {
      this.showItemDialog(params['sheet'], params['item'], params['peek'], params['isNew']);
    } else if (params['form']) {
      this.showFormDialog(params['form']);
    }
  }

  async showItemDialog(sheetId: number, itemId: string, peekId: string | undefined, isNew) {
    let sheet = await this.sheetRepo.getSheetById(sheetId);
    if (!peekId) {
      peekId = sheet.options.peek || 'side';
    }
    if (peekId == 'side') {
      // let ngbModalRef = this.modalService.open(SidebarItemComponent, {
      //   windowClass: 'sidebar-modal',
      // });
      // ngbModalRef.componentInstance.sheet = sheet;
      // ngbModalRef.componentInstance.itemId = itemId;
      // ngbModalRef.componentInstance.init();
      // ngbModalRef.result.finally(() => {
      //   this.router.navigate([], { queryParams: {} });
      // });
      this.showSidePeekDialog(sheet, itemId);
    } else {
      let peek = sheet.peeks.find((p) => p.id == peekId);
      if (!peek) return;
      this.showPeekDialog(itemId, isNew == 'true', sheet, peek);
    }
  }

  showPeekDialog(itemId, isNew, sheet, peek) {
    this.dialog.showPeekDialog(itemId, isNew, sheet, peek).onDestroy.subscribe(() => {
      this.router.navigate([], { queryParams: {} });
    });
  }

  showSidePeekDialog(sheet: SheetDto, itemId: string) {
    this.dialog.showSidePeekDialog(sheet, itemId,'info').onDestroy.subscribe(() => {
      this.router.navigate([], { queryParams: {} });
    });
  }

  private showFormDialog(code: string) {
    let ngbModalRef = this.modalService.open(FormFillDialogComponent, { size: 'lg' });
    let instance = ngbModalRef.componentInstance as FormFillDialogComponent;
    instance.formCode = code;
    ngbModalRef.result.finally(() => {
      this.router.navigate([], { queryParams: {} });
    });
  }
}
